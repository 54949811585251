import React, { useEffect, useRef, useState } from 'react'
import Compressor from 'compressorjs'
import { Button } from '@nextui-org/react'

import { IMAGE_COMPRESS_SIZE } from '@/constants/length'
import TrimmingContainer from '@/components/molecules/Trimming'
import ImageEdit from '@/assets/img/icons/ImageEdit.svg'
import ImageEdit_WH from '@/assets/img/icons/ImageEdit_w.svg'
import { Colors } from '@/constants/styles/color'
import FanmeLoading from '@/components/atoms/FanmeLoading'
import { MAX_UPLOAD_IMAGE_SIZE } from '@/constants'
import AlertDialog from '@/components/molecules/Alert/alert'
import Dialog from '@/components/atoms/PopUp'

interface Props {
  avatarImage: string
  creatorName: string
  accountIdentity: string
  slowUpdate?: boolean
  onGetCanvas?: (e: any) => any
  iconWidth?: number
  editIconWidth?: number
}

const AvatarSelect = ({
  avatarImage,
  creatorName,
  accountIdentity,
  slowUpdate,
  onGetCanvas,
  iconWidth,
  editIconWidth,
}: Props) => {
  const imageRef = useRef<HTMLInputElement>(null)

  const [currentImg, setCurrentImg] = useState<any>(null)
  const [imgSrc, setImgSrc] = useState<any>(null)
  const [trimModal, setTrimModal] = useState<boolean>(false)
  const [imageCanvas, setImageCanvas] = useState<HTMLCanvasElement>()
  const [loaded, setLoaded] = useState(false)
  // プロフィール画像内容超過のアラート
  const [alertDialogVisible, setAlertDialogVisible] = useState(false)

  const uploadAvatarImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoaded(true)
    // ファイル読み込み
    const reader = new FileReader()
    if (e.target.value && e.currentTarget.files !== null) {
      const file = e.currentTarget.files[0]

      //if filesize is larger than 32MB, show error message
      if (file.size > MAX_UPLOAD_IMAGE_SIZE) {
        setImgSrc(currentImg)
        setAlertDialogVisible(true)
        e.target.value = ''
        setLoaded(false)
        return
      } else if (file.size > IMAGE_COMPRESS_SIZE) {
        new Compressor(file, {
          quality: 0.4,
          success(result) {
            reader.readAsDataURL(result)
          },
          error(err) {
            console.log(err.message)
          },
        })
      } else {
        await reader.readAsDataURL(file)
      }
    }

    reader.onload = (readerEvent: ProgressEvent<FileReader>) => {
      setLoaded(false)
      if (readerEvent.target !== null) {
        setImgSrc((readerEvent.target as FileReader).result as string)
        setTrimModal(true)
      }
    }
  }

  const onClickUpload = () => {
    if (imageRef.current) {
      imageRef.current.click()
    }
  }

  useEffect(() => {
    setCurrentImg(avatarImage)
    setImgSrc(avatarImage)
  }, [avatarImage])

  useEffect(() => {
    if (imageCanvas) {
      setImgSrc(imageCanvas.toDataURL())
      setCurrentImg(imageCanvas.toDataURL())
    }
    onGetCanvas && onGetCanvas(imageCanvas)
  }, [imageCanvas])

  const cancel = () => {
    const inputImageElem = document.getElementById('inputImage') as HTMLInputElement
    if (inputImageElem) {
      inputImageElem.value = ''
    }
    setImgSrc(currentImg)
    setTrimModal(false)
  }

  if (loaded) return <FanmeLoading />
  return (
    <>
      {alertDialogVisible && (
        <AlertDialog
          visible={alertDialogVisible}
          message="ファイルサイズが32MBを超えています。"
          action={() => setAlertDialogVisible(false)}
          close={() => setAlertDialogVisible(false)}
          actionMessage={'閉じる'}
        />
      )}
      {trimModal && (
        <TrimmingContainer
          img={imgSrc}
          aspectRatio={1}
          type="circle"
          cropWidth={328}
          selectedFile={imgSrc}
          creatorName={creatorName}
          accountIdentity={accountIdentity}
          setTrimModal={setTrimModal}
          setSelectedFile={setImageCanvas}
          slowUpdate={slowUpdate}
          cancel={cancel}
        />
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
          borderRadius: '50%',
          marginLeft: 'auto',
          marginRight: 'auto',
          background: Colors.GRAY_BLUE,
          width: iconWidth ? `${iconWidth}px` : '112px',
          height: iconWidth ? `${iconWidth}px` : '112px',
        }}
      >
        {imgSrc && (
          <img
            src={imgSrc}
            alt=""
            width={iconWidth ? iconWidth : 112}
            height={iconWidth ? iconWidth : 112}
            style={{
              objectFit: 'cover',
              borderRadius: '50%',
            }}
            onError={() => setImgSrc(undefined)}
          />
        )}
        <input
          ref={imageRef}
          type="file"
          accept="image/*"
          id="inputImage"
          onChange={uploadAvatarImage}
          hidden
        />
        <Button
          icon={
            imgSrc ? (
              <ImageEdit_WH
                style={{
                  width: editIconWidth ? `${editIconWidth / 1.6}px` : '30px',
                  height: editIconWidth ? `${editIconWidth / 1.6}px` : '30px',
                }}
              />
            ) : (
              <ImageEdit
                style={{
                  width: editIconWidth ? `${editIconWidth / 1.6}px` : '30px',
                  height: editIconWidth ? `${editIconWidth / 1.6}px` : '30px',
                }}
              />
            )
          }
          css={{
            w: editIconWidth ? `${editIconWidth}px` : '48px',
            h: editIconWidth ? `${editIconWidth}px` : '48px',
            minWidth: editIconWidth ? `${editIconWidth}px` : '48px',
            minHeight: editIconWidth ? `${editIconWidth}px` : '48px',
            borderRadius: '50%',
            background: imgSrc ? Colors.TRANSPARENT_GRAY : Colors.WHITE,
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            margin: 'auto',
            boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.08)',
            p: 0,
          }}
          onClick={onClickUpload}
        />
      </div>
    </>
  )
}

export default AvatarSelect
