import React from 'react'
import styled from '@emotion/styled'
import CloseM from '@/assets/img/icons/Close_M.svg'
import ArrowBack from '@/assets/img/icons/Arrow_Back.svg'
import { Colors } from '@/constants/styles/color'

const HalfModalHeaderContainer = styled.div<{ background?: string }>`
  width: 100%;
  height: 48px;
  background: ${props => props.background || Colors.PRIMARY_GRAY};
  padding: 0 16px;
  border-radius: 16px 16px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const HalfModalHeaderTitle = styled.div<{ textColor?: string }>`
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  color: ${props => props.textColor || Colors.WHITE};
`

type Props = {
  title?: string
  background?: string
  textColor?: string
  onBack?: () => void
  onClose?: () => void
}

const HalfModalHeader = ({ title, background, textColor, onBack, onClose }: Props) => {
  return (
    <HalfModalHeaderContainer background={background}>
      {onBack && (
        <ArrowBack
          fill={background === Colors.WHITE ? Colors.BLACK : Colors.WHITE}
          style={{
            position: 'absolute',
            left: '16px',
          }}
          onClick={onBack}
        />
      )}
      <HalfModalHeaderTitle textColor={textColor}>{title}</HalfModalHeaderTitle>
      {!onBack && onClose && (
        <CloseM
          width={16}
          height={16}
          fill={background === Colors.WHITE ? Colors.BLACK : Colors.WHITE}
          style={{
            position: 'absolute',
            right: '12px',
          }}
          onClick={onClose}
        />
      )}
    </HalfModalHeaderContainer>
  )
}

export default HalfModalHeader
