import React, { useState } from 'react'
import EditProfilePopup from '@/components/molecules/MyMenu/EditProfilePopup'
import StyledBirthdaySetting from '@/assets/img/general_event/birthday_setting.svg'
import StyledBirthdayWeek from '@/assets/img/general_event/birthday_week_icon.webp'

import {
  StyledBirthdayBackgroundImage,
  StyledContainer,
  StyledContent,
  StyledSettingButton,
  StyledBirthdayWeekImage,
} from '@/components/molecules/Profile/BirthdayInput/index.style'

interface BirthdayInputProps {
  marginTop?: string
  marginBottom?: string
  maxWidth?: string
}

const BirthdayInput: React.FC<BirthdayInputProps> = ({
  marginTop = '10px',
  marginBottom = '10px',
  maxWidth = '100%',
}) => {
  const [editProfilePopupVisible, setEditProfilePopupVisible] = useState(false)

  const leftValue =
    maxWidth === '100%'
      ? window.innerWidth > 420
        ? '25%'
        : window.innerWidth > 390
        ? '22%'
        : '19%'
      : '19%'

  return (
    <StyledContainer>
      <StyledBirthdayBackgroundImage
        marginTop={marginTop}
        marginBottom={marginBottom}
        maxWidth={maxWidth}
      >
        <StyledContent>
          <StyledBirthdayWeekImage src={StyledBirthdayWeek.src} />
          <StyledBirthdaySetting
            style={{ width: '200px', position: 'absolute', top: '18%', left: leftValue }}
          />
        </StyledContent>
        <StyledSettingButton onClick={() => setEditProfilePopupVisible(true)}>
          設定
        </StyledSettingButton>
        <EditProfilePopup
          visible={editProfilePopupVisible}
          onClose={() => setEditProfilePopupVisible(false)}
        />
      </StyledBirthdayBackgroundImage>
    </StyledContainer>
  )
}

export default BirthdayInput
