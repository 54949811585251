import React from 'react'
import styled from '@emotion/styled'
import { Colors } from '@/constants/styles/color'
import { useAlertStore } from '@/utils/hooks/useAlert'

const ModalHeaderWithSaveContainer = styled.div<{ background?: string }>`
  width: 100%;
  height: 56px;
  background: ${props => props.background || Colors.PRIMARY_GRAY};
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const ModalHeaderWithSaveTitle = styled.div<{ textColor?: string }>`
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  color: ${props => props.textColor || Colors.WHITE};
`

const StyledCancelButton = styled.div<{ textColor?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${p => (p.textColor ? p.textColor : Colors.WHITE)};
  font-size: 11px;
  font-weight: 400;
  line-height: 11px;
  cursor: pointer;
  margin-left: 16px;
  &:hover {
    color: ${Colors.VERY_LIGHT_GRAY};
  }
`

const StyledSaveButton = styled.div<{ disabled: boolean }>`
  width: 64px;
  height: 32px;
  border-radius: 16px;
  background-color: ${p => (!p.disabled ? Colors.FANME_YELLOW : Colors.GRAY)};
  color: ${p => (!p.disabled ? Colors.PRIMARY_GRAY : Colors.LIGHT_GRAY)};
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  cursor: ${p => (!p.disabled ? 'pointer' : 'default')};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
`

type Props = {
  title?: string
  background?: string
  textColor?: string
  disabled?: boolean
  onCancel?: () => void
  onAction: () => void
}

const ModalHeaderWithSave = ({
  title,
  background,
  textColor,
  disabled = false,
  onCancel,
  onAction,
}: Props) => {
  const { onOpen } = useAlertStore()

  return (
    <ModalHeaderWithSaveContainer background={background}>
      <StyledCancelButton textColor={textColor} onClick={onCancel ? onCancel : onOpen}>
        キャンセル
      </StyledCancelButton>
      <ModalHeaderWithSaveTitle textColor={textColor}>{title}</ModalHeaderWithSaveTitle>
      <StyledSaveButton disabled={disabled} onClick={() => !disabled && onAction()}>
        保存
      </StyledSaveButton>
    </ModalHeaderWithSaveContainer>
  )
}

export default ModalHeaderWithSave
