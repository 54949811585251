import { create } from 'zustand'

interface editProfileState {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
}

export const useEditProfiletStore = create<editProfileState>()(set => ({
  isOpen: false,
  onOpen: () => set({ isOpen: true }),
  onClose: () => set({ isOpen: false }),
}))

interface TokushohoModalState {
  isVisibleTokushoho: boolean
  creatorId: string | null
  openModal: (creatorId: string) => void
  closeModal: () => void
}

export const useTokushohoModalStore = create<TokushohoModalState>(set => ({
  isVisibleTokushoho: false,
  creatorId: null,
  openModal: creatorId => set({ isVisibleTokushoho: true, creatorId }),
  closeModal: () => set({ isVisibleTokushoho: false, creatorId: null }),
}))

interface BuppanTokushohoModalState {
  isVisibleBuppanTokushoho: boolean
  creatorId: string | null
  openModal: (creatorId: string) => void
  closeModal: () => void
}

export const useBuppanTokushohoModalStore = create<BuppanTokushohoModalState>(set => ({
  isVisibleBuppanTokushoho: false,
  creatorId: null,
  openModal: creatorId => set({ isVisibleBuppanTokushoho: true, creatorId }),
  closeModal: () => set({ isVisibleBuppanTokushoho: false, creatorId: null }),
}))
