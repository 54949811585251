import React, { ReactNode } from 'react'
import styled from '@emotion/styled'
import { Colors } from '@/constants/styles/color'
import styles from '../style.module.scss'

const StyledContainer = styled.div<{ zIndex?: number }>`
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: ${p => (p.zIndex ? p.zIndex : 9999)};
  top: 0;
  left: 0;
`
const StyledInnerWrapper = styled.div<{ background?: string }>`
  position: relative;
  max-width: 480px;
  background: ${({ background }) => background || Colors.WHITE};
  height: 100%;
  margin: 0 auto;
  box-shadow: var(--nextui-shadows-lg);
`
const StyledDialogBodyWrapper = styled.div<{ padding?: string; height?: string }>`
  padding: ${p => p.padding || '12px'};
  height: ${p => p.height || '100vh'};
  height: ${p => p.height || '100svh'};
  align-items: center;
  text-align: center;
  overflow-y: auto;
  overflow-x: hidden;
`

const StyledBottomSpacer = styled.div`
  padding: 72px;
`

type Props = {
  header: ReactNode
  content: ReactNode
  visible: boolean
  zIndex?: number
  background?: string
  bodyPadding?: string
  bodyHeight?: string
}

const FullModal = ({
  header,
  content,
  visible,
  zIndex,
  background,
  bodyPadding,
  bodyHeight,
}: Props) => {
  if (!visible) return null
  return (
    <StyledContainer className={styles.popup} zIndex={zIndex}>
      <StyledInnerWrapper background={background}>
        {header}
        <StyledDialogBodyWrapper padding={bodyPadding} height={bodyHeight}>
          {content}
          <StyledBottomSpacer />
        </StyledDialogBodyWrapper>
      </StyledInnerWrapper>
    </StyledContainer>
  )
}

export default FullModal
