export const MAX_USER_NAME_LENGTH = 20
export const MAX_FANME_ID_LENGTH = 30
export const MAX_USER_MESSAGE_LENGTH = 500
export const MAX_VIDEO_TITLE_LENGTH = 200
export const MAX_VIDEO_DESCRIPTION_LENGTH = 500
export const MAX_POPUP_TITLE_LENGTH = 200
export const MAX_POPUP_LINK_LENGTH = 200
export const MAX_POPUP_INPUT_LENGTH = 20

export const YOUTUBE_LINK_PATTERNS = [
  'https://www.youtube.com/',
  'https://youtu.be/',
  'https://m.youtube.com/',
]

export const SHORTS_LINK_PATTERNS = [
  'https://youtube.com/shorts/',
  'https://www.youtube.com/embed/',
  'https://youtube.com/embed/',
]

export const TIKTOK_LINK_PATTERNS = ['https://vt.tiktok.com/', 'https://www.tiktok.com/']

export const PREFIX_OLD_MINI_APP = ['faq']

export const BLOCK_TYPE_FREE_SIZE = 1
export const BLOCK_TYPE_2_BLOCK = 2
export const BLOCK_TYPE_3_BLOCK = 3
export const BLOCK_TYPE_4_BLOCK = 4
export const BLOCK_TYPE_IMAGE_LIST = 5
export const BLOCK_TYPE_TEXT_LIST = 6

export const ADD_IMAGE_LEVEL_FREE_SIZE = BLOCK_TYPE_FREE_SIZE
export const ADD_IMAGE_LEVEL_2_BLOCK = BLOCK_TYPE_2_BLOCK
export const ADD_IMAGE_LEVEL_3_BLOCK = BLOCK_TYPE_3_BLOCK
export const ADD_IMAGE_LEVEL_4_BLOCK = BLOCK_TYPE_4_BLOCK

export const DISPLAY_INFO_BLOG = true

export const COVER_TYPE_PHOTO = 'photo'
export const COVER_TYPE_VIDEO = 'video'
export const MAX_UPLOAD_VIDEO_SIZE = 50000000
export const MAX_UPLOAD_IMAGE_SIZE = 32 * 1024 * 1024 // 32MB limit

export const TRANSFER_COMMISSION = 440

export enum NOTIFICATION_TYPES {
  REJECTED = -1,
  MANAGEMENT = 0,
  COMPLETED = 1,
  MONEY = 2,
  REQUEST = 3,
  GENERAL = 4,
  REACTION = 5,
  RECEIVE_FAN_LETTER = 6,
}

export const DEFAULT_NOTIFICATION_TITLE = 'お知らせ'
