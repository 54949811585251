import React, { useCallback } from 'react'
import Router from 'next/router'
import { event as gevent } from '@/pages/api/gtag'
import SellerAppIcon from '@/assets/img/logo/Seller_app_Icon.svg'
import CreatorPageSellerAppIcon from '@/assets/img/logo/CreatorPage_Seller_app_Icon.svg'

import { useThemeContext } from '@/contexts/ThemeContext'
import HeadeLine from '@/components/atoms/HeadLine'
import { StyledMyMenuItem, StyledMyMenuItemName, StyledSettingButton } from '../index.style'

interface Props {
  isCreatorPage?: boolean
}

const FanmeApps = ({ isCreatorPage }: Props) => {
  const { isDark, themeColors } = useThemeContext()

  const handleClick = useCallback((isMenu?: boolean) => {
    if (isMenu) {
      gevent({
        action: 'menu_miniapp',
        category: 'other',
      })
    }
    Router.push('/apps')
  }, [])
  return (
    <>
      {isCreatorPage ? (
        <div className="px-6 py-0">
          <HeadeLine text="販売" textColor={themeColors.text} />
          <StyledSettingButton isDark={isDark} onClick={handleClick.bind(null, false)}>
            <CreatorPageSellerAppIcon
              width={24}
              height={24}
              style={{
                filter: isDark ? '' : 'brightness(0) invert(1)',
                position: 'absolute',
                left: '20px',
              }}
            />
            商品を登録する
          </StyledSettingButton>
        </div>
      ) : (
        <StyledMyMenuItem
          onClick={handleClick.bind(null, true)}
          className="cursor-pointer"
          width="100%"
        >
          <SellerAppIcon width={20} height={20} />
          <StyledMyMenuItemName>販売アプリ</StyledMyMenuItemName>
        </StyledMyMenuItem>
      )}
    </>
  )
}

export default FanmeApps
