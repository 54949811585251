import React from 'react'
import styled from '@emotion/styled'
import { Colors } from '@/constants/styles/color'

const StyledHeadeLine = styled.div<{ textColor?: string }>`
  min-height: 24px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  border-left: 1px solid ${props => props.textColor || Colors.PRIMARY_GRAY};
  padding-left: 12px;
  > label {
    font-family: 'Noto Sans JP';
    font-weight: 500;
    font-size: 13px;
    line-height: 13px;
    color: ${props => props.textColor || Colors.PRIMARY_GRAY};
  }
`

type Props = {
  text: string
  children?: React.ReactNode
  textColor?: string
}

const HeadeLine = ({ text, children, textColor }: Props) => {
  return (
    <StyledHeadeLine textColor={textColor}>
      <label>{text}</label>
      {children && children}
    </StyledHeadeLine>
  )
}

export default HeadeLine
