import React from 'react'
import { Text } from '@nextui-org/react'

import Check from '@/assets/img/icons/Check.svg'
import Triangle from '@/assets/img/icons/Triangle.svg'
import { Colors } from '@/constants/styles/color'

interface Props {
  text: string
  top?: number
}

const ItemNotification = ({ text, top }: Props) => {
  return (
    <div
      style={{
        maxWidth: '480px',
        width: '100%',
        position: 'absolute',
        top: top !== undefined ? top : '-25px',
        zIndex: '1',
      }}
    >
      <div
        style={{
          display: 'flex',
          maxWidth: '328px',
          width: '100%',
          height: '40px',
          filter: 'drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.08))',
          transform: 'translateZ(0)',
          borderRadius: '20px',
          margin: '0px auto',
          background: Colors.WHITE,
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingLeft: '4px',
            paddingRight: '4px',
            position: 'relative',
            width: '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              width: '32px',
              height: '32px',
              borderRadius: '50%',
              background: Colors.FANME_YELLOW,
            }}
          >
            <Check
              fill={Colors.BLACK}
              style={{
                margin: 'auto',
                width: '20px',
                height: '20px',
              }}
            />
          </div>
          <Text
            color={Colors.BLACK}
            style={{
              fontWeight: '700',
              fontSize: '13px',
              lineHeight: '13px',
            }}
          >
            {text}
          </Text>
          <div style={{ width: '32px' }} />
          <div
            style={{
              position: 'absolute',
              bottom: '-16px',
              left: 'calc(50% - 12px)',
            }}
          >
            <Triangle fill={Colors.WHITE} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ItemNotification
