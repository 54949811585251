import styled from '@emotion/styled'
import { Colors } from '@/constants/styles/color'

const StyledBirthdayContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`

const StyledBirthdaySelect = styled.select<{ hasError: boolean; width: string }>`
  padding: 8px;
  border-radius: 4px;
  border: 1px solid ${({ hasError }) => (hasError ? Colors.RED : Colors.LIGHT_GRAY)};
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-align: center;
  width: ${({ width }) => width ?? 'auto'};
`

const StyledBirthdaySpan = styled.span`
  margin: 0 4px;
  font-size: 14px;
`

const StyledBirthdayClearButton = styled.button`
  padding: 2px 12px;
  font-size: 12px;
  margin-left: 16px;
  background-color: ${Colors.WHITE};
  color: ${Colors.BLACK};
  border: 1px solid ${Colors.BLACK};
  border-radius: 20px;
  cursor: pointer;
`

export {
  StyledBirthdayContainer,
  StyledBirthdaySelect,
  StyledBirthdaySpan,
  StyledBirthdayClearButton,
}
