import React from 'react'
import FanmeDialog from '@/components/molecules/Alert/index'

interface Props {
  message: string
  actionMessage: string
  action: () => void
  close: (v: AlertState) => void
  visible: boolean
}

type AlertState = {
  message: string
  actionMessage: string
  action: () => void
  close: (v: AlertState) => void
  visible: boolean
}

const AlertDialog = ({ message, actionMessage, action, close, visible }: Props) => {
  const closeArg: AlertState = {
    message: message,
    actionMessage: actionMessage,
    action: action,
    close: close,
    visible: false,
  }
  return (
    <FanmeDialog
      text={message}
      visible={visible}
      action={actionMessage}
      onClose={() => close(closeArg)}
      onAction={() => action()}
      cancelOn
      exclamationIconOn
    />
  )
}

export default AlertDialog
export type { AlertState }
