import React from 'react'
import styled from '@emotion/styled'
import ArrowBack from '@/assets/img/icons/Arrow_Back.svg'
import { Colors } from '@/constants/styles/color'

const FullModalHeaderContainer = styled.div<{ background?: string }>`
  width: 100%;
  height: 56px;
  background: ${props => props.background || Colors.PRIMARY_GRAY};
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const FullModalHeaderTitle = styled.div<{ textColor?: string }>`
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  color: ${props => props.textColor || Colors.WHITE};
`

type Props = {
  title?: string
  background?: string
  textColor?: string
  onBack?: () => void
}

const FullModalHeader = ({ title, background, textColor, onBack }: Props) => {
  return (
    <FullModalHeaderContainer background={background}>
      {onBack && (
        <ArrowBack
          fill={Colors.WHITE}
          style={{
            position: 'absolute',
            left: '16px',
          }}
          onClick={onBack}
        />
      )}
      <FullModalHeaderTitle textColor={textColor}>{title}</FullModalHeaderTitle>
    </FullModalHeaderContainer>
  )
}

export default FullModalHeader
