import styled from '@emotion/styled'
import BirthdayBackgroundImage from '@/assets/img/general_event/birthday_event.webp'
import { Colors } from '@/constants/styles/color'

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`

const StyledBirthdayBackgroundImage = styled.div<{
  marginTop: string
  marginBottom: string
  maxWidth: string
}>`
  width: 88%;
  max-width: ${({ maxWidth }) => maxWidth};
  height: 56px;
  background-image: url(${BirthdayBackgroundImage.src});
  background-size: cover;
  background-position: top;
  position: relative;
  border-radius: 8px;
  margin-top: ${({ marginTop }) => marginTop};
  margin-bottom: ${({ marginBottom }) => marginBottom};
`

const StyledContent = styled.div`
  position: absolute;
  top: 5px;
  left: 10px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  align-items: flex-start;
`

const StyledSettingButton = styled.button`
  position: absolute;
  bottom: 10px;
  right: 8px;
  width: 64px;
  height: 32px;
  background-color: ${Colors.BLACK};
  border-radius: 16px;
  color: ${Colors.WHITE};
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
`

const StyledBirthdayWeekImage = styled.img`
  width: 54px;
`

export {
  StyledContainer,
  StyledBirthdayBackgroundImage,
  StyledContent,
  StyledSettingButton,
  StyledBirthdayWeekImage,
}
