import React from 'react'
import { Button } from '@nextui-org/react'

type Props = {
  text: string
  width?: string
  height?: string
  textColor?: string
  bgColor?: string
  border?: string
  borderRadius?: string
  fontFamily?: string
  fontSize?: string
  fontWeight?: string
  lineHeight?: string
  letterSpacing?: string
  padding?: string

  icon?: React.ReactElement
  iconRight?: React.ReactElement

  disable?: boolean
  onClick?: () => void
}

const ButtonBase = ({
  text,
  height,
  width,
  textColor,
  bgColor,
  border,
  borderRadius,
  fontFamily,
  fontSize,
  fontWeight,
  lineHeight,
  icon,
  iconRight,
  letterSpacing,
  padding,
  disable,
  onClick,
}: Props) => {
  return (
    <Button
      data-testid="button"
      css={{
        w: width,
        minWidth: width,
        h: height ?? '$12',
        br: borderRadius ?? '$sm',
        color: textColor,
        border,
        bgColor,
        fontFamily,
        fs: fontSize,
        fontWeight,
        lh: lineHeight,
        letterSpacing,
        p: padding ?? 0,
      }}
      iconRight={iconRight ?? ''}
      icon={icon ?? ''}
      disabled={disable}
      onClick={onClick}
    >
      {text}
    </Button>
  )
}
export default ButtonBase
